import "tailwindcss/tailwind.css";
import "public/main.css";

import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { withTRPC } from "@trpc/next";
import { AppType } from "next/dist/shared/lib/utils";
import { AppRouter } from "server/routers/_app";
import { SessionProvider } from "next-auth/react";

const MyApp: AppType = ({ Component, pageProps: { session, ...pageProps } }) => {
	return (
		<SessionProvider session={session}>
			<SkeletonTheme baseColor="#808080" highlightColor="#202020">
				<Component {...pageProps} />
			</SkeletonTheme>
		</SessionProvider>
	);
};

export default withTRPC<AppRouter>({
	config({ ctx }) {
		/**
		 * If you want to use SSR, you need to use the server's full URL
		 * @link https://trpc.io/docs/ssr
		 */
		return {
			url:
				typeof window !== "undefined"
					? "/api/trpc"
					: process.env.VERCEL_URL
					? `https://${process.env.VERCEL_URL}/api/trpc` // vercel.com
					: `http://localhost:${process.env.PORT ?? 3000}/api/trpc`, // assume localhost
			/**
			 * @link https://react-query-v3.tanstack.com/reference/QueryClient
			 */
			// queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
		};
	},
	/**
	 * @link https://trpc.io/docs/ssr
	 */
	ssr: false,
})(MyApp);
